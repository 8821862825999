import percentageSeen from "@/utils/percentageSeen";

// Анимимирует значение от 0 до значения в data-animate-count. Срабатывает когда доскролено до элементов
const countNumberAnimation = ($elements, start = 0, duration = 3000) => {
    Array.from($elements).forEach(($el) => {
        let seenPercent = percentageSeen($el);
        let end = +$el.dataset.animateCount;

        if (seenPercent >= 10 && !$el.hasAttribute("data-animated-count")) {
            if (start === end) return;
            var range = end - start;
            var current = start;
            var increment = end > start ? 1 : -1;
            var stepTime = Math.abs(Math.floor(duration / range));
            var timer = setInterval(function() {
                current += increment;
                $el.innerHTML = current;
                if (current == end) {
                    clearInterval(timer);
                }
            }, stepTime);

            $el.dataset.animatedCount = "";
        }
    });
};

export default countNumberAnimation;
