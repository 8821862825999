import percentageSeen from "@/utils/percentageSeen";

const slideToCenterTextAnimation = ($elements) => {
    Array.from($elements).forEach(($el) => {
        let $text = $el.querySelectorAll("[data-slide-text-child]");
        let $leftText = $text[0];
        let $rightText = $text[1];
        let seenPercent = percentageSeen($el);

		let textPos = 100 - (seenPercent*100)/50;
		if(textPos <= 0) textPos = 0;

		let leftTextPos = textPos;
		let rightTextPost = 0 - textPos;

        $leftText.style.transform = `translateX(${leftTextPos}%)`;
        $rightText.style.transform = `translateX(${rightTextPost}%)`;
    });
};

export default slideToCenterTextAnimation;
