<template>
	<div class="content packsContent oldTemplate">
		<div class="packsBlueSection">
			<div class="setMenuColor" data-menu-color="light">
				<transition name="fade2">
					<div
						class="packsBanner"
						ref="banner"
					>
						<canvas ref="bannerCanvas" class="packsBanner__canvas_video"></canvas>
						<video :src="require('@/assets/img_new/projectsSlider/packs.mp4')" autoplay loop  muted="" playsinline="" :poster="require(`@/assets/img/packs/banner.jpg`)" class="packsBanner__video" ref="bannerVideo"></video>
						<div class="container packsBanner__main">
							<p class="packsBanner--title">Мир упаковки</p>
							<p class="packsBanner--desc">Интернет-магазин товаров для дома и офиса</p>
						</div>
					</div>
				</transition>
			</div>
			<div class="blockLeftMenu">
				<div
					class="blockLeftMenu__item setMenuColor"
					data-menu-color="light"
				>
					<div class="container">
						<div class="blockLeftMenu__left big">
							<div class="blockLeftMenu__left__content">
								<p>О проекте</p>
							</div>
						</div>
						<div class="blockLeftMenu__right">
							<div class="packsInfo">
								<div class="blockLeftMenu__right_row">
									<div class="blockLeftMenu__right_col">
										<p class="lh26">
											Мир Упаковки — торгово-закупочная компания по продаже упаковки, расходных материалов и хозяйственных товаров. Компания работает по всему Татарстану. В Казани, Набережных Челнах, Нижнекамске и Альметьевске открыты пункты самовывоза и склады Мир Упаковки.
										</p>
									</div>
									<div class="blockLeftMenu__right_col">
										<a href="http://packs.ru/" target="_blank" class="mb12 hover-red">packs.ru</a>
										<p class="mb12">
											срок разработки: 4 месяца
										</p>
										<p>запуск: 1.10.2020</p>
									</div>
								</div>
								<div class="blockLeftMenu__right_row">
									<div class="blockLeftMenu__right_col">
										<div class="packsInfo__item">
											<div class="packsInfo__title">
												Задачи:
											</div>
											<div class="packsInfo__text">
												Провести анализ технических и маркетинговых показателей действующего сайта. Разработать новый интернет-магазин со свежим дизайном. Выполнить интеграцию по остаткам позиций и ценам в филиалах клиента. Спроектировать личные кабинеты покупателей для юридических и физических лиц. 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mb90-60-40">
				<div class="packsHalfText2" data-slide-text>
					<div class="packsHalfText2__blue">
						<div class="container">
							<div class="packsHalfText2__white_text" data-slide-text-child>
								<span>Решение</span>
							</div>
						</div>
					</div>
					<div
						class="packsHalfText2__white setMenuColor"
						data-menu-color="dark"
					>
						<div class="container">
							<div class="packsHalfText2__blue_text" data-slide-text-child>
								<span>Решение</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="blockLeftMenu">
			<div class="blockLeftMenu__item whiteBack">
				<div class="container">
					<div class="blockLeftMenu__left hide1200">
						<div class="blockLeftMenu__left__content"></div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="blockLeftMenu__right_row">
							<div class="blockLeftMenu__right_col">
								<div class="packsComment">
									<div class="packsComment__quot">“</div>
									<div class="packsComment__content">
										<div class="packsComment__text">
											Начали работу с проведения маркетингового исследования. Основная ниша клиента - это хозяйственные товары и принадлежности для офиса. <br>
											Вместе с заказчиком выделили основных региональных конкурентов. Нам предстояло спроектировать новый проект так, чтобы он стал основным инструментом для отделов закупок в офисах и компаниях городов присутствия Мир Упаковки. 
										</div>
										<div class="packsUser">
											<div
												class="packsUser__photo"
												:style="{
													backgroundImage:
														'url(' +
														require(`@/assets/img/packs/user1.jpg`) +
														')',
												}"
											></div>
											<div class="packsUser__content">
												<div class="packsUser__name">
													Алексей Денисов
												</div>
												<div class="packsUser__desc">
													Менеджер проектов Артрокетс
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="blockLeftMenu__item noBorder noTopPadding whiteBack setMenuColor"
				data-menu-color="dark"
			>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>
								Дизайн
							</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="mb40">
							Сформулировали три главных принципа дизайна проекта: минималистичный интерфейс,  адаптивность, масштабируемость.
						</p>
						<img
							:src="require(`@/assets/img/packs/design1.png`)"
							alt=""
							class="idea--image wow fadeIn mb90-60-40"
							data-wow-duration="1s"
						/>
						<p class="fs20 bold mb24">Проектирование</p>
						<div class="mb60">
							Использовали результаты исследований, чтобы спроектировать интернет-магазин, с которым удобно работать и совершать покупки.
							<br>
							Продумали, какие бизнес-задачи должен выполнять тот или иной элемент интерфейса,и как их органично реализовать в дизайне.
						</div>

						<div class="mb90-60">
							<div class="packsFeatures">
								<div class="packsFeature">
									<div class="packsFeature__title" data-animate-count="40">0</div>
									<div class="packsFeature__text">
										Страниц было <br class="hide600" />
										спроектированно
									</div>
								</div>
								<div class="packsFeature">
									<div class="packsFeature__title" data-animate-count="52">0</div>
									<div class="packsFeature__text">
										Страницы было <br class="hide600" />
										отрисованно
									</div>
								</div>
								<div class="packsFeature">
									<div class="packsFeature__title" data-animate-count="13">0</div>
									<div class="packsFeature__text">
										Попапов было отрисованно
										<br class="hide600" />
										для улучшенной комуникации
									</div>
								</div>
							</div>
						</div>

						<div class="hide600">
							<div class="imagesThreeCol">
								<div class="imagesThreeCol__col">
									<img
										:src="
											require(`@/assets/img/packs/design2.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
									<img
										:src="
											require(`@/assets/img/packs/design3.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
								<div class="imagesThreeCol__col">
									<img
										:src="
											require(`@/assets/img/packs/design4.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
									<img
										:src="
											require(`@/assets/img/packs/design5.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
									<img
										:src="
											require(`@/assets/img/packs/design6.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
								<div class="imagesThreeCol__col">
									<img
										:src="
											require(`@/assets/img/packs/design7.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
									<img
										:src="
											require(`@/assets/img/packs/design8.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
									<img
										:src="
											require(`@/assets/img/packs/design9.jpg`)
										"
										alt=""
										class="imagesThreeCol__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
							</div>
						</div>
						<div class="show600">
							<PhotoScroll
								:data="prototypes"
								:clickable="prototypes.clickable"
							></PhotoScroll>
						</div>
					</div>
				</div>
			</div>

			<div class="container setMenuColor mb90-60-40" data-menu-color="dark">
				<div class="packsTitle">
					Каталог <br />
					продукции
				</div>
			</div>

			<div class="blockLeftMenu__item noBorder noTopPadding whiteBack setMenuColor" data-menu-color="dark">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Каталог</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="mb40">
							Много внимания уделили проработке каталога продукции: <br>
							<ul>
								<li>1. Показ актуальных остатков товара в магазинах. Остатки синхронизируются с 1С. </li>
								<li>2. Доработали поиск в каталоге. Поиск происходит по части слова, артикулу товара или даже по ошибочно введенному слову.</li>
								<li>3. Добавление параметров фильтра без перезагрузки страницы. Каждый пункт учитывает взаимосвязь уже выбранных параметров, исключая получение пустого результата.</li>
								<li>4. У товаров, которые продаются упаковками, выводятся цены за упаковку, они добавляются в корзину в количестве, указанном на упаковке.</li>
							</ul>
						</p>

						<div class="mb60-40-40">
							<img
								:src="
									require(`@/assets/img/packs/design10.jpg`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>

						<div class="fs20 bold mb24">
							Навигация по каталогу
						</div>
						<p class="mb40">
						У Заказчика более 40 000 уникальных товарных позиций, некоторые из них схожи названиями или областью применения. Как правило, покупатели ищут что-то конкретное, поэтому мы скрупулезно проработали вложенность категорий и разделов, чтобы найти нужный товар стало интуитивно понятно. 
						</p>

						<div class="mb40">
							<img
								:src="
									require(`@/assets/img/packs/design11.jpg`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>

						<p class="mb40">
							Отдельным блоком мы выделили основные, наиболее посещаемые категории товаров. Поиск нужного товара сокращается на 1-2 шага.
						</p>

						<div class="mb60-40-40">
							<img
								:src="
									require(`@/assets/img/packs/design12.jpg`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>

						<div class="fs20 bold mb24">Прорисовка иконок</div>
						<p class="mb40">
							Отрисовали индивидуальный пак иконок, созвучный стилистике проекта. Иконки выполнены в единой цветовой гамме сайта и легко узнаваемы.
						</p>

						<div>
							<img
								:src="
									require(`@/assets/img/packs/design13.jpg`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="packsBlueSection2 setMenuColor" data-menu-color="light">
			<div class="container">
				<div class="packsTitle packsBlueSection2__title">
					<p class="mb24-24-16">Карточка</p>
					<p class="white">товара</p>
				</div>
			</div>

			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left hide1200">
						<div class="blockLeftMenu__left__content"></div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="blockLeftMenu__right_row">
							<div class="blockLeftMenu__right_col">
								<div class="packsComment">
									<div class="packsComment__quot white">
										“
									</div>
									<div class="packsComment__content">
										<div class="packsComment__text">
											При разработке карточки товара сделали акцент на её эргономичности. Для каждого товара выводим артикул и название с основными характеристиками товара. Здесь же отображается актуальная цена за единицу или упаковку - для 90% людей этой информации достаточно для принятия решения о покупке. 
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="blockLeftMenu__item noBorder noTopPadding noBottomPadding">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Карточка товара</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="mb90-60-40">
							<img
								:src="
									require(`@/assets/img/packs/design14.png`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>

						<div class="fs20 bold mb24">
							Полезная информация под рукой
						</div>
						<p class="mb40">
							Не забыли о “Законе о защите прав потребителей” и на странице разместили информацию о возврате, покупке и доставке товара. Сюда же выводим сертификаты соответствия и иные документы. 
						</p>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left big"></div>
					<div class="blockLeftMenu__right">
						<PhotoScroll
							:data="productCardSlider"
							:clickable="productCardSlider.clickable"
							class="packsPhotoScroll packsPhotoScroll--white"
						></PhotoScroll>
					</div>
				</div>
			</div>
		</div>

		<div class="mb90-60-40">
			<div class="packsHalfText2" data-slide-text>
				<div class="packsHalfText2__blue">
					<div class="container">
						<div class="packsHalfText2__white_text" data-slide-text-child>
							<span>Корзина</span>
						</div>
					</div>
				</div>
				<div
					class="packsHalfText2__white setMenuColor"
					data-menu-color="dark"
				>
					<div class="container">
						<div class="packsHalfText2__blue_text" data-slide-text-child>
							<span>Корзина</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="packsSection setMenuColor" data-menu-color="dark">
			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left hide1200">
						<div class="blockLeftMenu__left__content"></div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="blockLeftMenu__right_row">
							<div class="blockLeftMenu__right_col">
								<div class="packsComment">
									<div class="packsComment__quot">
										“
									</div>
									<div class="packsComment__content">
										<div class="packsComment__text">
											Корзина делится на 3 части - товары в наличии, под заказ и отложенные. Товары каждой категории выводятся отдельными блоками.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Корзина</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="mb60-40-40">
							<img
								:src="
									require(`@/assets/img/packs/design15.png`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>
						<div class="fs20 bold mb24">
							Настройка списка 
						</div>
						<p class="mb40">
							Отображение товаров в корзине можно настроить и выводить списком или плитками.
						</p>
						<div class="mb60-40-40">
							<img
								:src="
									require(`@/assets/img/packs/design16.png`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>
						<div class="fs20 bold mb24">
							Малая корзина
						</div>
						<p class="mb40">
							Корзина всегда под рукой, товар добавляется моментально без перезагрузки страницы. 
						</p>
						<div class="mb40 hide600">
							<img
								:src="
									require(`@/assets/img/packs/design17.png`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>
						<div class="mb40 show600 packsFullwidthSm">
							<img
								:src="
									require(`@/assets/img/packs/design17-mobile.jpg`)
								"
								alt=""
								class="wow fadeIn"
								data-wow-duration="1s"
							/>
						</div>
						<p>
							Предусмотрели возможность изменить количество единиц товара, удалить или добавить товар в «отложенный» без посещения основной корзины. 
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="container mb90-60-40">
			<div class="packsTitle">
				Оформление <br />
				заказа
			</div>
		</div>
		<div class="blockLeftMenu__item noBorder noTopPadding">
			<div class="container">
				<div class="blockLeftMenu__left big">
					<div class="blockLeftMenu__left__content">
						<p>Форма заказа</p>
					</div>
				</div>
				<div class="blockLeftMenu__right">
					<p class="mb40">
						Максимально упростили процесс оформления заказа и свели его до 3 шагов: <br>
						1. Выбор типа клиента: физическое или юридическое лицо, и заполнение контактных данных.
					</p>

					<div class="mb40-24">
						<img
							:src="require(`@/assets/img/packs/design18.png`)"
							alt=""
							class="wow fadeIn"
							data-wow-duration="1s"
						/>
					</div>
					<p class="mb40">
						2. Выбор способа доставки: самовывоз, транспортная компания или курьерская доставка. <br>
						Самовывоз доступен только в городах, где есть филиалы компании. Доступные для отгрузки офисы выводятся отдельно. <br>
						При доставке транспортной компанией или курьером выводим блок для заполнения данных по доставке - после оплаты заказа, информация отправляется в службу доставки.
					</p>
					<div class="mb40-24">
						<img
							:src="require(`@/assets/img/packs/design19.png`)"
							alt=""
							class="wow fadeIn"
							data-wow-duration="1s"
						/>
					</div>
					<p class="mb40">
						3. Выбор способа оплаты. <br>
						Для b2c клиентов предусмотрен выбор как наличного расчета с курьером доставки, так и оплаты банковской картой на сайте. Для b2b оплата производится по расчетному счету. Счет на оплату генерируется автоматически.
					</p>
					<div class="mb40-24">
						<img
							:src="require(`@/assets/img/packs/design20.png`)"
							alt=""
							class="wow fadeIn"
							data-wow-duration="1s"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="blockLeftMenu__item noBorder noTopPadding">
			<div class="container">
				<div class="blockLeftMenu__left big">
					<div class="blockLeftMenu__left__content">
						<p>Личный кабинет</p>
					</div>
				</div>
				<div class="blockLeftMenu__right">
					<p class="mb60-40-40">
						На сайте реализовано два личных кабинета: для физических и юридических лиц. Так, личный кабинет  b2c клиента не перегружен лишним функционалом b2b. 
					</p>

					<div class="">
						<img
							:src="require(`@/assets/img/packs/design21.png`)"
							alt=""
							class="wow fadeIn"
							data-wow-duration="1s"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="packsBlueSection3 setMenuColor" data-menu-color="light">
			<div class="container">
				<div class="packsTitle packsBlueSection3__title">
					Калькуляторы
				</div>
			</div>

			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left hide1200">
						<div class="blockLeftMenu__left__content"></div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="blockLeftMenu__right_row">
							<div class="blockLeftMenu__right_col">
								<div class="packsComment">
									<div class="packsComment__quot white">
										“
									</div>
									<div class="packsComment__content">
										<div class="packsComment__text">
											Компания клиента не только продает товары, но и производит упаковку под заказ. Разработали и внедрили калькуляторы расчета стоимости производства пакетов и скотча под заказ.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left hide1200">
						<div class="blockLeftMenu__left__content"></div>
					</div>
					<div class="blockLeftMenu__right">
						<img
							:src="require(`@/assets/img/packs/design22.png`)"
							alt=""
							class="wow fadeIn"
							data-wow-duration="1s"
						/>
					</div>
				</div>
			</div>

			<div class="blockLeftMenu__item noBorder noTopPadding">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Интеграции</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<p class="mb60-40-40">
							Провели интеграцию с сервисами, обязательные для e-coomerce:
						</p>

						<div class="features2">
							<div class="features2__col">
								<div class="features2__img_wrap">
									<img
										:src="
											require(`@/assets/img/packs/1c.svg`)
										"
										alt=""
										class="features2__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
								<div class="features2__text">
									двухсторонняя интеграция с 1С - все данные пользователей моментально отправляются в товаро- учетную систему Заказчика.
								</div>
							</div>
							<div class="features2__col">
								<div class="features2__img_wrap">
									<img
										:src="
											require(`@/assets/img/packs/atol.png`)
										"
										alt=""
										class="features2__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
								<div class="features2__text">
									интеграция с онлайн-кассой и системой оплаты в полном соответствии с ФЗ 54.
								</div>
							</div>
							<div class="features2__col">
								<div class="features2__img_wrap">
									<img
										:src="
											require(`@/assets/img/packs/sber.svg`)
										"
										alt=""
										class="features2__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
								<div class="features2__text">
									интеграция с платежной системой для приема платежей банковской картой.
								</div>
							</div>
							<div class="features2__col">
								<div class="features2__img_wrap">
									<img
										:src="
											require(`@/assets/img/packs/pek.png`)
										"
										alt=""
										class="features2__img wow fadeIn"
										data-wow-duration="1s"
									/>
								</div>
								<div class="features2__text">
									интеграция с курьерской службом - для автоматического расчета стоимости доставки.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mb90-60-40">
			<div class="packsHalfText2" data-slide-text>
				<div class="packsHalfText2__blue">
					<div class="container">
						<div class="packsHalfText2__white_text" data-slide-text-child>
							<span>Адаптивность</span>
						</div>
					</div>
				</div>
				<div
					class="packsHalfText2__white setMenuColor"
					data-menu-color="dark"
				>
					<div class="container">
						<div class="packsHalfText2__blue_text" data-slide-text-child>
							<span>Адаптивность</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="blockLeftMenu">
			<div class="blockLeftMenu__item whiteBack">
				<div class="container">
					<div class="blockLeftMenu__left hide1200">
						<div class="blockLeftMenu__left__content"></div>
					</div>
					<div class="blockLeftMenu__right">
						<div class="blockLeftMenu__right_row">
							<div class="blockLeftMenu__right_col">
								<div class="packsComment">
									<div class="packsComment__quot">“</div>
									<div class="packsComment__content">
										<div class="packsComment__text">
											Весь функционал сайта и пользовательский интерфейс  доступен как с мобильных устройств и планшетов, так и с компьютера.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item noBorder noTopPadding whiteBack">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>
								Tablet <br class="hide1200" />
								768 px.
							</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<img
							:src="require(`@/assets/img/packs/design23.png`)"
							alt=""
							class="idea--image wow fadeIn"
							data-wow-duration="1s"
						/>
					</div>
				</div>
			</div>
			<div class="blockLeftMenu__item noBorder noTopPadding whiteBack">
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>
								Mobile <br class="hide1200" />
								320 px.
							</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<PhotoScroll
							:data="mobileAdaptive"
							:clickable="mobileAdaptive.clickable"
						></PhotoScroll>
					</div>
				</div>
			</div>
			<!-- <div
				class="blockLeftMenu__item abdBlock14 setMenuColor"
				data-menu-color="dark"
			>
				<div class="container">
					<div class="blockLeftMenu__left big">
						<div class="blockLeftMenu__left__content">
							<p>Другие проекты</p>
						</div>
					</div>
					<div class="blockLeftMenu__right">
						<Projects
							:data="projectsData"
							:quantity="3"
							:quantityTable="3"
							:quantityMobile="3"
							:showPagination="false"
							:smallElements="true"
						>
						</Projects>
					</div>
				</div>
			</div> -->
		</div>

		<section
			class="fullPageSectionContainer setMenuColor newForm"
			data-menu-color="light"
		>
			<div class="fullPageSection">
				<div
					class="fullPageSection--back"
					style="background-color: #262E39"
				></div>
				<Feedback :type="2"></Feedback>
			</div>
		</section>
	</div>
</template>

<style lang="sass">
@import '@/assets/sass/case/packs/Packs'
@import '@/assets/sass/WithLeftMenu'
@import '@/assets/sass/images/imagesThreeCol'
</style>

<script>
import PhotoScroll from "@/components/app/PhotoScroll";
import projectsData from "@/assets/json/projects.json";
import Projects from "@/components/app/Projects";
import slideToCenterTextAnimation from "@/utils/slideToCenterTextAnimation";
import countNumberAnimation from "@/utils/countNumberAnimation";
import throttle from "@/utils/throttle";

var leftMenuItems = null;
function leftMenuItemsPosition() {
    leftMenuItems.forEach((item) => {
        var parent = item.closest(".blockLeftMenu__left");
        var parentTop =
            parent.getBoundingClientRect().top +
            document.documentElement.scrollTop;
        var parentLeft = parent.getBoundingClientRect().x;
        var parentHeight = parent.offsetHeight;
        var parentWidth = parent.offsetWidth;
        var itemTop = item.offsetTop;
        var scrollTop = window.pageYOffset;
        var windowWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

        var top = 28;

        if (windowWidth < 1440) {
            top = 70;
        }

        if (scrollTop + item.offsetHeight >= parentTop + parentHeight - top) {
            item.style.top = "auto";
            item.style.bottom = "0px";
            item.style.position = "absolute";
            item.style.width = parentWidth + "px";
            item.style.left = "";
        } else if (scrollTop + top < parentTop) {
            item.style.top = "";
            item.style.bottom = "";
            item.style.position = "";
            item.style.width = "";
            item.style.left = "";
        } else {
            item.style.top = top + "px";
            item.style.bottom = "auto";
            item.style.position = "fixed";
            item.style.width = parentWidth + "px";
            item.style.left = parentLeft + "px";
        }
    });
}

export default {
    metaInfo: {
        title:
            "Разработка интернет-магазина продовольственных товаров Мир Упаковки | «‎Артрокетс»",
        meta: [
            {
                vmid: "description",
                name: "description",
                content:
                    "Подробная информация о выполненном проекте и об этапах работ по созданию интернет-магазина для компании Мир Упаковки.",
            },
            {
                vmid: "og:title",
                property: "og:title",
                content:
                    "Разработка интернет-магазина продовольственных товаров Мир Упаковки | «‎Артрокетс»",
            },
            {
                vmid: "og:description",
                property: "og:description",
                content:
                    "Подробная информация о выполненном проекте и об этапах работ по созданию интернет-магазина для компании Мир Упаковки.",
            },
        ],
    },
    data: () => ({
        projectsData,
        prototypes: {
            clickable: true,
            nameType: "big",
            images: [
                {
                    image: require("@/assets/img/packs/prototype1.jpg"),
                    fullImage: require("@/assets/img/packs/prototype1.jpg"),
                },
                {
                    image: require("@/assets/img/packs/prototype1.jpg"),
                    fullImage: require("@/assets/img/packs/prototype1.jpg"),
                },
                {
                    image: require("@/assets/img/packs/prototype1.jpg"),
                    fullImage: require("@/assets/img/packs/prototype1.jpg"),
                },
                {
                    image: require("@/assets/img/packs/prototype1.jpg"),
                    fullImage: require("@/assets/img/packs/prototype1.jpg"),
                },
            ],
        },
        productCardSlider: {
            fullwidth: true,
            clickable: true,
            nameType: "big",
            images: [
                {
                    image: require("@/assets/img/packs/product-card-1.png"),
                    fullImage: require("@/assets/img/packs/product-card-1.png"),
                },
                {
                    image: require("@/assets/img/packs/product-card-2.png"),
                    fullImage: require("@/assets/img/packs/product-card-2.png"),
                },
                {
                    image: require("@/assets/img/packs/product-card-3.png"),
                    fullImage: require("@/assets/img/packs/product-card-3.png"),
                },
                {
                    image: require("@/assets/img/packs/product-card-4.png"),
                    fullImage: require("@/assets/img/packs/product-card-4.png"),
                },
            ],
        },
        personalSlider: {
            fullwidth: true,
            clickable: false,
            nameType: "big",
            images: [
                {
                    image: require("@/assets/img/packs/lk1.png"),
                    fullImage: require("@/assets/img/packs/lk1.png"),
                },
                {
                    image: require("@/assets/img/packs/lk2.png"),
                    fullImage: require("@/assets/img/packs/lk2.png"),
                },
                {
                    image: require("@/assets/img/packs/lk3.png"),
                    fullImage: require("@/assets/img/packs/lk3.png"),
                },
                {
                    image: require("@/assets/img/packs/lk4.png"),
                    fullImage: require("@/assets/img/packs/lk4.png"),
                },
                {
                    image: require("@/assets/img/packs/lk5.png"),
                    fullImage: require("@/assets/img/packs/lk5.png"),
                },
                {
                    image: require("@/assets/img/packs/lk6.png"),
                    fullImage: require("@/assets/img/packs/lk6.png"),
                },
            ],
        },
        mobileAdaptive: {
            clickable: true,
            nameType: "big",
            images: [
                {
                    image: require("@/assets/img/packs/mobile-adaptive1.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive1.png"),
                },
                {
                    image: require("@/assets/img/packs/mobile-adaptive2.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive2.png"),
                },
                {
                    image: require("@/assets/img/packs/mobile-adaptive3.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive3.png"),
                },
                {
                    image: require("@/assets/img/packs/mobile-adaptive4.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive4.png"),
                },
                {
                    image: require("@/assets/img/packs/mobile-adaptive5.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive5.png"),
                },
                {
                    image: require("@/assets/img/packs/mobile-adaptive6.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive6.png"),
                },
                {
                    image: require("@/assets/img/packs/mobile-adaptive7.png"),
                    fullImage: require("@/assets/img/packs/mobile-adaptive7.png"),
                },
            ],
        },
    }),
    methods: {
        slideTextAnim() {
            slideToCenterTextAnimation(this.$slideTextElements);
        },
        animateCount() {
            countNumberAnimation(this.$countAnimElements);
        },
        setBannerVideoSizes() {
            let vidWidth = this.$refs.bannerVideo.videoWidth;
            let vidHeight = this.$refs.bannerVideo.videoHeight;

            this.$refs.bannerCanvas.width = this.$refs.bannerVideo.offsetWidth;
            this.$refs.bannerCanvas.height = this.$refs.bannerVideo.offsetHeight;

            //redraw canvas after resize
            ctx.drawImage(
                this.$refs.bannerVideo,
                0,
                0,
                vidWidth,
                vidHeight, // source rectangle
                0,
                0,
                this.$refs.bannerCanvas.width,
                this.$refs.bannerCanvas.height
            ); // destination rectangle);
        },
    },
    mounted() {
        this.$slideTextElements = document.querySelectorAll(
            "[data-slide-text]"
        );
        this.$countAnimElements = document.querySelectorAll(
            "[data-animate-count]"
        );

        leftMenuItems = document.querySelectorAll(
            ".blockLeftMenu__left__content"
        );
        window.onscroll = function () {
            leftMenuItemsPosition();
        };
        window.onresize = function () {
            leftMenuItemsPosition();
        };

        window.addEventListener("scroll", throttle(this.slideTextAnim, 100));
        window.addEventListener("scroll", throttle(this.animateCount, 100));

        let bannerVideo = this.$refs.bannerVideo;
        let bannerCanvas = this.$refs.bannerCanvas;
        let banner = this.$refs.banner;

        bannerVideo.play();

        let ctx = bannerCanvas.getContext("2d");

        let bannerVideoWidth;
        let bannerVideoHeight;

        bannerVideo.onloadedmetadata = function () {
            bannerVideoWidth = bannerVideo.videoWidth;
            bannerVideoHeight = bannerVideo.videoHeight;

            bannerCanvas.width = bannerVideo.offsetWidth;
            bannerCanvas.height = bannerVideo.offsetHeight;

            drawingLoop();

            setTimeout(() => {
                setVideoBgColor(bannerVideo, banner);
            }, 150);
        };

        let requestId;

        function drawingLoop() {
            requestId = window.requestAnimationFrame(drawingLoop);

            ctx.drawImage(
                bannerVideo,
                0,
                0,
                bannerVideoWidth,
                bannerVideoHeight,
                0,
                0,
                bannerCanvas.width,
                bannerCanvas.height
            );
        }

        function setVideoBgColor(vid, backgroundElement) {
            // draw first four pixel of video to a canvas
            // then get pixel color from that canvas
            setTimeout(() => {
                let canvas = document.createElement("canvas");
                canvas.width = 8;
                canvas.height = 8;

                let ctx = canvas.getContext("2d");
                ctx.drawImage(vid, 0, 0, 8, 8);

                let p = ctx.getImageData(0, 0, 8, 8).data;
                //dont take the first but fourth pixel [r g b]
                if (p[60] && p[60] !== "0") {
                    backgroundElement.style.backgroundColor =
                        "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
                    document.querySelector(
                        ".packsBlueSection"
                    ).style.backgroundColor =
                        "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
                    document.querySelector(
                        ".packsHalfText2__blue"
                    ).style.backgroundColor =
                        "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
                    document.querySelector(
                        ".packsHalfText2__blue_text"
                    ).style.color =
                        "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
                } else {
                    backgroundElement.style.backgroundColor = "";
                    document.querySelector(
                        ".packsBlueSection"
                    ).style.backgroundColor = "";
                    document.querySelector(
                        ".packsHalfText2__blue"
                    ).style.backgroundColor = "";
                    document.querySelector(
                        ".packsHalfText2__blue_text"
                    ).style.color = "";
                }
            }, 20);
        }

        window.addEventListener("resize", this.setBannerVideoSizes);
    },
    unmounted() {
        window.removeEventListener("scroll", this.slideTextAnim);
        window.removeEventListener("scroll", this.animateCount);
        window.removeEventListener("resize", this.setBannerVideoSizes);
    },
    components: {
        PhotoScroll,
        Projects,
        Feedback: () => import("@/components/new/Forms/Feedback"),
    },
};
</script>
